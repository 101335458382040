import { 
  UPDATE_CHANNEL_LIST_LOADING,
  UPDATE_CHANNEL_LIST_PAGINATION,
  UPDATE_FETCH_CHANNEL_LIST_DATA,
  UPDATE_FETCH_CHANNEL_TYPE_LIST
} from "../action/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  channelType: []
}

const channelList = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_CHANNEL_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_CHANNEL_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_FETCH_CHANNEL_LIST_DATA:
      return {
        ...state,
        data: payload.data,
        totalData : payload.totalData
      }
    case UPDATE_FETCH_CHANNEL_TYPE_LIST:
      return {
        ...state,
        channelType: payload.channelType
      }
    default:
      return state
  }
}

export default channelList