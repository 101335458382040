export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST"
export const COUPON_BOOK_REQUEST = "COUPON_BOOK_REQUEST"
export const VERIFY_TYPE_REQUEST = "VERIFY_TYPE_REQUEST"
export const COUPON_BOOK_DROPDOWN = "COUPON_BOOK_DROPDOWN"
export const CREATE_CAMPAIGN_LOADING = "CREATE_CAMPAIGN_LOADING"
export const COUPON_DETAIL_RESPONSE = "COUPON_DETAIL_RESPONSE"
export const UPDATE_TO_INITIAL_VALUE = "UPDATE_TO_INITIAL_VALUE"
export const CAMPAIGN_STATUS_DROPDOWN = "CAMPAIGN_STATUS_DROPDOWN"
export const UPDATE_CAMPAIGN_ID = "UPDATE_CAMPAIGN_ID"
export const SAVE_STATUS = "SAVE_STATUS"
export const SENDER_LIST_REQUEST = "SENDER_LIST_REQUEST"
export const UPDATE_SENDER_REQUEST  = "UPDATE_SENDER_REQUEST "