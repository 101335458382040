import { 
  SUBMIT_PROFILE_LOADING,
  GET_PROFILE_FORM,
  UPDATE_PROFILE_FORM,
  SUBMIT_UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_IMG_ECM,
  GET_CREDIT_DATA
} from "../action/types"

const initialState = {
  loading: false,
  requestSaveData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  },
  profileEcmId: "",
  creditData: {
    creditBalance: "",
    creditTotle: "",
    creditDatePurc: ""
  }
}

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_PROFILE_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_PROFILE_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    case GET_PROFILE_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          phone: payload.phone
        }
      }
    case SUBMIT_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          currentPassword: initialState.requestSaveData.currentPassword,
          newPassword: initialState.requestSaveData.newPassword,
          confirmPassword: initialState.requestSaveData.confirmPassword
        }
      }
    case UPDATE_PROFILE_IMG_ECM:
      return {
        ...state,
        profileEcmId: payload
      }
    case GET_CREDIT_DATA:
      return {
        ...state,
        creditData: {
          ...state.creditData,
          creditBalance: payload.creditBalance,
          creditTotle: payload.creditTotle,
          creditDatePurc: payload.creditDatePurc
        }
      }
    default:
      return state
  }
}

export default profileReducer
