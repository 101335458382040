// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import main from "./main"
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import template from "./template"
import coupon from "@modules/coupon/store/reducers"
import survey from "@modules/surveyOld/store/reducers"
import surveyPage from "@modules/surveyOld/store/surveyPage/reducers"
import surveyView from "@modules/surveyOld/store/surveyView/reducers"
import updatePassword from "@modules/authentication/store/updatePassword/reducers"
import login from "@modules/authentication/store/login/reducers"
import homeDashboard from "@modules/home/store/homeDashboard/reducers"
import homeDetail from "@modules/home/store/homeDetail/reducers"
import gameConfiguration from "@modules/game/store/gameConfiguration/reducers"
import createGame from "@modules/game/store/createGame/reducers"
import tag from "@modules/people/store/tag/reducers"
import tagDetail from "@modules/people/store/tagDetail/reducers"
import backlist from "@modules/people/store/backlist/reducers"
import people from "@modules/people/store/people/reducers"
import memberList from "@modules/member/store/memberList/reducers"
import memberView from "@modules/member/store/memberView/reducers"
import memberGroup from "@modules/member/store/memberGroup/reducers"
import memberCreate from "@modules/member/store/memberCreate/reducers"
import memberForm from "@modules/member/store/memberForm/reducers"
import memberUser from "@modules/member/store/memberUser/reducers"
import memberDashboard from "@modules/member/store/memberDashboard/reducers"
import memberOtp from "@modules/member/store/memberOTP/reducers"
import campaignForm from "@modules/campaign/store/campaignForm/reducers"
import boardcast from "@modules/campaign/store/boardcast/reducers"
import couponCreateForm from "@modules/coupon/store/couponCreateForm/reducers"
import gameDashboard from "@modules/game/store/dashboard/reducers"
import createSurvey from "@modules/survey/store/createSurvey/reducers"
import listSurvey from "@modules/survey/store/listSurvey/reducers"
import editSurvey from "@modules/survey/store/editSurvey/reducers"
import formSurvey from "@modules/survey/store/formSurvey/reducers"
import dashboardSurvey from "@modules/survey/store/dashboardSurvey/reducers"
import coupons from "@modules/coupons/store/coupons/reducers"
import createCoupon from "@modules/coupons/store/createCoupon/reducers"
import couponDetail from "@modules/coupons/store/couponDetail/reducres"
import couponsBook from "@modules/coupons/store/couponsBook/reducers"
import keyverification from "@modules/coupons/store/keyverification/reducers"
import couponLandingPage from "@modules/coupons/store/couponLandingPage/reducers"
import listLandingPage from "@modules/landingPage/store/listLandingPage/reducers"
import createLandingPage from "@modules/landingPage/store/createLandingPage/reducers"
import registerOtp from "@modules/pages/authentication/store/registerOTP/reducers"
import register from "@modules/pages/authentication/store/register/reducers"
import profile from "@modules/profile/store/profile/reducers"
import channelList from "@modules/channel/store/channelList/reducers"
import configList from "@modules/configs/store/configList/reducers"

const rootReducer = combineReducers({
  main,
  auth,
  navbar,
  layout,
  template,

  // authentication
  updatePassword,
  login,
  registerOtp,
  register,


  // homeDashboard
  homeDashboard,
  homeDetail,

  //people
  tag,
  tagDetail,
  backlist,
  people,

  coupon,
  couponCreateForm,
  survey,
  surveyPage,
  surveyView,

  //member
  memberList,
  memberView,
  memberGroup,
  memberCreate,
  memberForm,
  memberUser,
  memberDashboard,
  memberOtp,

  //game
  gameConfiguration,
  createGame,
  gameDashboard,

  //campaign
  campaignForm,
  boardcast,

  //survey
  createSurvey,
  listSurvey,
  editSurvey,
  formSurvey,
  dashboardSurvey,

  //coupons
  coupons,
  createCoupon,
  couponDetail,
  couponsBook,
  keyverification,
  couponLandingPage,

  //landingPage
  listLandingPage,
  createLandingPage,

  profile,
  channelList,
  configList
})

export default rootReducer
