import { 
  UPDATE_CONFIG_LIST_LOADING,
  UPDATE_CONFIG_LIST_PAGINATION,
  UPDATE_FETCH_CONFIG_LIST_DATA,
  UPDATE_FETCH_EDIT_CONFIG_API_DATA,
  CLEAR_EDIT_CONFIG_API_DATA
} from "../action/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 0,
    perPage: 10
  },
  configAPI: {
    name: "",
    key: "",
    secret: "",
    statusCode: 0
  }
}

const configList = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_CONFIG_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_CONFIG_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_FETCH_CONFIG_LIST_DATA:
      return {
        ...state,
        data: payload.data,
        totalData : payload.totalData
      }
    case UPDATE_FETCH_EDIT_CONFIG_API_DATA:
      return {
        ...state,
        configAPI: {
          name: payload.name,
          key: payload.key,
          secret: payload.secret,
          statusCode: payload.statusCode
        }
      }
    case CLEAR_EDIT_CONFIG_API_DATA:
      return {
        ...state,
        configAPI: initialState.configAPI
      }
    default:
      return state
  }
}

export default configList