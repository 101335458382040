import {
  SUBMIT_LANDINGPAGE,
  UPDATE_REQUEST_VALUE,
  GET_LANDINGPAGE_DETAIL,
  GET_LANDINGPAGE_DETAIL_LOADING,
  CLEAR_LANDINGPAGE_DETAIL,
  UPDATE_LANDINGPAGE_IMG_ECM,
  UPDATE_LANDINGPAGE_SAVE_LOADING
} from "../actions/types"

const initialState = {
  loading: false,
  landingPage: {
    landingpage_name: "",
    landingpage_detail: ""
  },
  landingPageImgEcm: ""
}

const createLandingPageReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_LANDINGPAGE_DETAIL_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_LANDINGPAGE_SAVE_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_REQUEST_VALUE:
      return {
        ...state,
        landingPage: {
          ...state.landingPage,
          [payload.name]: payload.value
        }
      }
    case GET_LANDINGPAGE_DETAIL:
      return {
        ...state,
        landingPage: {
          landingpage_name: payload.landingpage_name,
          landingpage_detail: payload.landingpage_detail
        }
      }
    case UPDATE_LANDINGPAGE_IMG_ECM:
      return {
        ...state,
        landingPageImgEcm: payload
      }
    case CLEAR_LANDINGPAGE_DETAIL:
      return {
        ...state,
        landingPage : initialState.landingPage,
        landingPageImgEcm : initialState.landingPageImgEcm
      }
    default:
      return state
  }
}

export default createLandingPageReducer