import { 
  SUBMIT_REGISTER_LOADING,
  UPDATE_REGISTER_FORM
} from "../action/types"

const initialState = {
  loading: false,
  requestSaveData: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    allowOffer: false,
    termsConditions: false,
    statusSave: false
  }
}

const registerReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_REGISTER_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_REGISTER_FORM:
      return {
        ...state,
        requestSaveData: {
          ...state.requestSaveData,
          [payload.name]: payload.value
        }
      }
    default:
      return state
  }
}

export default registerReducer
