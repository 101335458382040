export const GET_REWARD_CONFIG_LIST_SUCCESS = "GET_REWARD_CONFIG_LIST_SUCCESS"
export const UPDATE_GAME_LIST_LOADING = "UPDATE_GAME_LIST_LOADING"
export const GET_GAME_LIST_SUCCESS = "GET_GAME_LIST_SUCCESS"
export const GET_MODE = "GET_MODE"
export const UPDATE_REWARD_GAME_LOADING = "UPDATE_REWARD_GAME_LOADING"
export const UPDATE_GAME_LIST_PAGINATION = "UPDATE_GAME_LIST_PAGINATION"
export const UPDATE_OPEN_MODAL = "UPDATE_OPEN_MODAL"
export const REDEEM_REWARD_SUCCESS = "REDEEM_REWARD_SUCCESS"
export const CLEAR_REDEEM_REWARD = "CLEAR_REDEEM_REWARD"
export const GET_SUMMARY_REWARD = "GET_SUMMARY_REWARD"
export const GET_REWARD_LIST = "GET_REWARD_LIST"
export const UPDATE_REWARD_LIST_PAGINATION = "UPDATE_REWARD_LIST_PAGINATION"
export const GET_GENERATE_LINK = "GET_GENERATE_LINK"
export const CLEAR_GENERATE_LINK = "CLEAR_GENERATE_LINK"
export const UPDATE_REWARD_LIST_LOADING = "UPDATE_REWARD_LIST_LOADING"
export const GET_REWARD_DETAIL = "GET_REWARD_DETAIL"
export const CLEAR_REWARD_DETAIL = "CLEAR_REWARD_DETAIL"
export const UPDATE_REWARD_REQUEST = "UPDATE_REWARD_REQUEST"
export const CLEAR_REWARD_DATA_LIST = "CLEAR_REWARD_DATA_LIST"
export const UPDATE_ACTIVE_GAME = "UPDATE_ACTIVE_GAME"
export const UPDATE_GAME_LIST_REQUEST = "UPDATE_GAME_LIST_REQUEST"
export const CLEAR_GAME_LIST_REQUEST = "CLEAR_GAME_LIST_REQUEST"
export const REDEEM_SUCCESS = "REDEEM_SUCCESS"
export const UPDATE_WINK_DISABLE = "UPDATE_WINK_DISABLE"
export const SAVE_INPUT_TEXT_SUCCESS = "SAVE_INPUT_TEXT_SUCCESS"
export const SAVE_PROFILE_LOADING = "SAVE_PROFILE_LOADING"
export const UPDATE_LINE_USER_PROFILE = "UPDATE_LINE_USER_PROFILE"
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS"
export const SENT_DUPLICATE_LOADING = "SENT_DUPLICATE_LOADING"
export const SENT_DUPLICATE_SUCCESS = "SENT_DUPLICATE_SUCCESS"
export const UPDATE_REWARD_DUPLICATE = "UPDATE_REWARD_DUPLICATE"