import { 
  GET_DASHBOARD_MAIN_DETAIL,
  UPDATE_DASHBOARD_MAIN_LOADING,
  GET_REDEEM_DASHBOARD_DETAIL,
  UPDATE_REDEEM_DASHBOARD_LOADING,
  UPDATE_REDEEM_LIST_PAGINATION,
  UPDATE_REQUEST_DASHBOARD,
  CLEAR_REQUEST_DASHBOARD
} from "../actions/types"

const initialState = {
  loadingDashboard: false,
  loadingRedeemDashboard: false,
  dashboardMainData: {
    active: 0,
    isEnd: null,
    redeemPercent: 0,
    redeemed: 0,
    unRedeemed: 0,
    redeemMonth: [],
    total: 0,
    unActive: 0,
    unRedeemPercent: 0,
    activePercent: 0,
    unActivePercent: 0,
    endPercent: 0,
    totalUser: 0
  },
  dashboardRedeemData: {
    gameName: '',
    gameCreateDate: '',
    total: 0,
    redeem: 0,
    unRedeem: 0,
    redeemPercent: 0,
    unRedeemPercent: 0,
    redeemPerMonth: [],
    notRedeemPerMonth: [],
    totalSentLine: [],
    totalSentOther: [],
    total: 0,
    active: false,
    isEnd: false,
    canDuplicate: false
  },
  redeemList : [],
  redeemListTotal: 0,
  redeemPagination: {
    currentPage: 1,
    perPage: 10
  },
  total: 0,
  Reward_total: 0,
  Expire_day: '',
  requestDashboard: {
    name: "",
    date: [],
    startDate: [],
    endDate: [],
    status: null
  }
}

const gameDashboard = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_DASHBOARD_MAIN_DETAIL :
      return {
        ...state,
        dashboardMainData: {
          active: payload.ACTIVE,
          isEnd: payload.IS_END,
          redeemPercent: payload.RedeemPercen,
          redeemMonth: payload.Redeem_month,
          total: payload.Total_Game,
          unActive: payload.UNACTIVE,
          unRedeemPercent: payload.UnRedeemPercen,
          activePercent: payload.ActivePercen,
          unActivePercent: payload.UnActivePercen,
          endPercent: payload.EndPercen,
          redeemed: payload.Redeem,
          unRedeemed: payload.UnRedeem,
          totalUser: payload.Total_User
        }
      }
    case UPDATE_DASHBOARD_MAIN_LOADING :
      return {
        ...state,
        loadingDashboard: payload
      }
    case GET_REDEEM_DASHBOARD_DETAIL :
      return {
        ...state,
        dashboardRedeemData: {
          gameName: payload.dashboardRedeemData.GAMENAME,
          gameCreateDate: payload.dashboardRedeemData.GAMECREATEDATE,
          Total: payload.dashboardRedeemData.Total,
          redeemPercent: payload.dashboardRedeemData.RedeemPercen,
          unRedeemPercent: payload.dashboardRedeemData.UnRedeemPercen,
          redeemPerMonth: payload.dashboardRedeemData.Redeem_month,
          notredeemPerMonth: payload.dashboardRedeemData.Not_Redeem_month,
          totalSentLine: payload.dashboardRedeemData.Total_Sent_line,
          totalSentOther: payload.dashboardRedeemData.Total_Sent_Other,
          redeemList : payload.dashboardRedeemData.Redeem_list,
          active: payload.dashboardRedeemData.IS_ACTIVE,
          isEnd: payload.dashboardRedeemData.IS_END,
          canDuplicate: payload.dashboardRedeemData.CAN_DUPLICATE,
          redeem: payload.dashboardRedeemData.Redeem,
          unRedeem: payload.dashboardRedeemData.UnRedeem
        },
        redeemList: payload.redeemList || [],
        total: payload.total,
        Reward_total: payload.Reward_total,
        Expire_day: payload.Expire_day
      }
    case UPDATE_REDEEM_DASHBOARD_LOADING :
      return {
        ...state,
        loadingRedeemDashboard: payload
      }
    case UPDATE_REDEEM_LIST_PAGINATION:
      return {
        ...state,
        redeemPagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_REQUEST_DASHBOARD:
      return {
        ...state,
        requestDashboard: {
          ...state.requestDashboard,
          [payload.name]: payload.value
        }
      }
    case CLEAR_REQUEST_DASHBOARD :
      return {
        ...state,
        requestDashboard: initialState.requestDashboard
      }
    default:
      return state
  }
}

export default gameDashboard