import {
  SUBMIT_LOADING_LIST_LANDINGPAGE,
  UPDATE_LANDINGPAGE_LIST_REQUEST,
  CLEAR_LANDINGPAGE_REQUEST,
  UPDATE_PAGINATION_LIST_LANDINGPAGE,
  UPDATE_LIST_DATA_LIST_LANDINGPAGE
} from "../actions/types"

const initialState = {
  loading: false,
  data: [],
  totalData: 0,
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  duplicateSuccess: false,
  requestLandingPageList: {
    name: null,
    status: null,
    createDate: []
  }
}

const listLandingPageReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT_LOADING_LIST_LANDINGPAGE:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_LIST_DATA_LIST_LANDINGPAGE:
      return {
        ...state,
        data: payload.data,
        totalData: payload.totalData
      }
    case UPDATE_PAGINATION_LIST_LANDINGPAGE:
      return {
        ...state,
        pagination:{
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_LANDINGPAGE_LIST_REQUEST:
      return {
        ...state,
        requestLandingPageList: {
          ...state.requestLandingPageList,
          [payload.name]: payload.value
        }
      }
    case CLEAR_LANDINGPAGE_REQUEST :
      return {
        ...state,
        requestLandingPageList: initialState.requestLandingPageList
      }
    default:
      return state
  }
}

export default listLandingPageReducer