import {
  GET_GAME_LIST_SUCCESS,
  UPDATE_GAME_LIST_LOADING,
  GET_REWARD_CONFIG_LIST_SUCCESS,
  UPDATE_REWARD_GAME_LOADING,
  GET_MODE,
  UPDATE_GAME_LIST_PAGINATION,
  UPDATE_OPEN_MODAL,
  REDEEM_REWARD_SUCCESS,
  CLEAR_REDEEM_REWARD,
  GET_SUMMARY_REWARD,
  GET_REWARD_LIST,
  UPDATE_REWARD_LIST_PAGINATION,
  GET_GENERATE_LINK,
  CLEAR_GENERATE_LINK,
  UPDATE_REWARD_LIST_LOADING,
  GET_REWARD_DETAIL,
  UPDATE_REWARD_REQUEST,
  CLEAR_REWARD_DETAIL,
  CLEAR_REWARD_DATA_LIST,
  UPDATE_GAME_LIST_REQUEST,
  CLEAR_GAME_LIST_REQUEST,
  CLEAR_GAME_LIST_DATA,
  REDEEM_SUCCESS,
  UPDATE_WINK_DISABLE,
  SAVE_INPUT_TEXT_SUCCESS,
  UPDATE_LINE_USER_PROFILE,
  SAVE_PROFILE_SUCCESS,
  SENT_DUPLICATE_SUCCESS,
  UPDATE_REWARD_DUPLICATE
} from "../actions/types"

const initialState = {
  gameListData: [],
  totalData: 0,
  rewardListData: [],
  loading: false,
  modeSelect: "edit",
  gameType: 1,
  openModal: false,
  errorMessage: false,
  saveInputTexterrorMessage: false,
  redeemSuccess: false,
  redeemSummary: [],
  rewardDataList: [],
  rewardDashboardList: [],
  rewardTotalData: 0,
  dashboardPagination: {
    currentPage: 1,
    perPage: 10
  },
  linkData: "",
  loadingDashboard: false,
  requestRewardDetail: {
    reId: '',
    reName: '',
    reQty: '',
    reMax: '',
    reChanceRate: '',
    reIsActive: false,
    reIsRedeem: false,
    startDate: "",
    endDate: "",
    reRedeemCode: "",
    reImgId: "",
    imageDisplay: ""
  },
  requestGameList: {
    gameType: "",
    isActive: null,
    isEnd: null,
    createStart: "",
    createEnd: ""
  },
  pagination: {
    currentPage: 1,
    perPage: 10
  },
  profileId: null,
  winkDisable: false,
  userPlayDuplicate: false,
  rewardId: "",
  rewardName: "",
  rewardImgId: "",
  rewardRedeem: "false",
  landingPageDetail: ""
}

const gameConfiguration = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CLEAR_GAME_LIST_REQUEST :
      return {
        ...state,
        requestGameList: initialState.requestGameList
      }
    case UPDATE_GAME_LIST_REQUEST:
      return {
        ...state,
        requestGameList: {
          ...state.requestGameList,
          [payload.name]: payload.value
        }
      }
    case CLEAR_REWARD_DATA_LIST :
      return {
        ...state,
        rewardListData: initialState.rewardListData
      }
    case UPDATE_REWARD_REQUEST:
      return {
        ...state,
        requestRewardDetail: {
          ...state.requestRewardDetail,
          [payload.name]: payload.value
        }
      }
    case CLEAR_REWARD_DETAIL :
      return {
        ...state,
        requestRewardDetail: initialState.requestRewardDetail
      }
    case GET_REWARD_DETAIL : 
    return {
      ...state,
      requestRewardDetail:  {
        reId: payload.REWARD_ID,
        reName: payload.REWARD_NAME,
        reQty: payload.REWARD_QTY,
        reChanceRate: payload.REWARD_CHANCE,
        reIsActive: payload.REWARD_ACTIVE,
        reIsRedeem: payload.REWARD_REDEEM,
        startDate: payload.REWARD_STARTDATE,
        endDate: payload.REWARD_ENDDATE,
        reRedeemCode: payload.REDEEM_CODE,
        reImgId: payload.REWARD_IMG_ID
      }
    }
    case UPDATE_REWARD_LIST_LOADING :
      return {
        ...state,
        loadingDashboard: payload
      }
    case CLEAR_GENERATE_LINK:
      return {
        ...state,
        linkData: initialState.linkData
      }
    case GET_GENERATE_LINK:
      return {
        ...state,
        linkData: payload
      }
    case UPDATE_REWARD_LIST_PAGINATION:
      return {
        ...state,
        dashboardPagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case UPDATE_GAME_LIST_PAGINATION:
      return {
        ...state,
        pagination: {
          currentPage: payload.currentPage,
          perPage: payload.perPage
        }
      }
    case GET_REWARD_LIST :
      return {
        ...state,
        rewardDashboardList: payload.rewardDashboardList,
        rewardTotalData: payload.rewardTotalData
      }
    case GET_SUMMARY_REWARD:
      return {
        ...state,
        redeemSummary: payload.redeemSummary,
        rewardDataList: payload.rewardDataList
      }
    case UPDATE_OPEN_MODAL:
      return {
        ...state,
        openModal: payload
      }
    case GET_MODE:
      return {
        ...state,
        modeSelect: payload.modeSelect,
        gameType: payload.gameType
      }
    case GET_GAME_LIST_SUCCESS:
      return {
        ...state,
        gameListData: payload.gameListData,
        totalData: payload.totalData
      }
    case UPDATE_GAME_LIST_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_REWARD_GAME_LOADING:
      return {
        ...state,
        loading: payload
      }
    case GET_REWARD_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        rewardListData: payload.rewardListData
      }
    case REDEEM_REWARD_SUCCESS:
      return {
        ...state,
        errorMessage: payload.data
      }
    case REDEEM_SUCCESS:
      return {
        ...state,
        redeemSuccess: payload.data
      }
    case CLEAR_REDEEM_REWARD:
      return {
        ...state,
        errorMessage: initialState.errorMessage
      }
    case UPDATE_WINK_DISABLE:
      return {
        ...state,
        winkDisable: payload.winkDisable
      }
    case SAVE_INPUT_TEXT_SUCCESS:
      return {
        ...state,
        saveInputTexterrorMessage: payload.data
      }
    case SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        profileId: payload.data
      }
    case SENT_DUPLICATE_SUCCESS:
      return {
        ...state,
        userPlayDuplicate: payload.data
      }
    case UPDATE_REWARD_DUPLICATE:
      return {
        ...state,
        rewardId: payload.rewardId,
        rewardName: payload.rewardName,
        rewardImgId: payload.rewardImgId,
        rewardRedeem: payload.rewardRedeem,
        landingPageDetail: payload.landingPageDetail
      }
    default:
      return state
  }
}

export default gameConfiguration